import React from 'react';

import Pattern from '../components/pattern/pattern.component';
import Layout from '../components/layout/layout.component';

const ContactPage = () => {
  return (
    <Layout>
      <div className='bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24'>
        <div className='relative max-w-xl mx-auto'>
          <Pattern
            className={'left-full transform translate-x-1/2'}
            SVGHeight={404}
            SVGWidth={404}
            patternID={'85737c0e-0916-41d7-917f-596dc7edfa27'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={404}
            rectWidth={404}
          />
          <Pattern
            className={'right-full bottom-0 transform -translate-x-1/2'}
            SVGHeight={404}
            SVGWidth={404}
            patternID={'85737c0e-0916-41d7-917f-596dc7edfa27'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={404}
            rectWidth={404}
          />
          <div className='text-center'>
            <h2 className='text-3xl font-bold font-fira tracking-tight text-fuchsia-700 sm:text-4xl'>
              Contact Us
            </h2>
            <p className='mt-4 text-lg leading-6 font-osans text-blue-gray-500'>
              We are always happy to get in contact with someone new, or get
              some feedback from our content.
            </p>
          </div>
          <div className='mt-12'>
            <form
              action='https://formspree.io/f/mzbykavw'
              method='POST'
              className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 font-fira'
            >
              <div className='sm:col-span-2'>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-blue-gray-700'
                >
                  Your name
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='name'
                    required
                    id='name'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-blue-gray-300 rounded-md'
                  />
                </div>
              </div>
              <div className='sm:col-span-2'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-blue-gray-700'
                >
                  Email
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    required
                    autoComplete='email'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-blue-gray-300 rounded-md'
                  />
                </div>
              </div>
              <div className='sm:col-span-2'>
                <label
                  htmlFor='message'
                  className='block text-sm font-medium text-blue-gray-700'
                >
                  Message
                </label>
                <div className='mt-1'>
                  <textarea
                    id='message'
                    name='message'
                    rows={4}
                    required
                    className='py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border border-blue-gray-300 rounded-md'
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className='sm:col-span-2'>
                <button
                  type='submit'
                  className='w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
